$(document).on("turbolinks:load", function() {
  bsCustomFileInput.init();

  $('#customFile').on('change',function(){
    var fileName = $(this).val();
    //https://github.com/Johann-S/bs-custom-file-input/pull/38/files
    //Dont know why this is still happening. Changelog says it should be done in code now.
    //https://github.com/Johann-S/bs-custom-file-input/blob/db0e8a2b068504154ff0b4acc978ff7ed617625a/CHANGELOG.md#130-2018-11-16

    var splitted = fileName.split('\\');
    fileName = splitted[splitted.length -1];
    //replace the "Choose a file" label
    $(this).next('.custom-file-label').html(fileName);
  })

})
