  let medsLoader = null;

  // document.addEventListener("turbolinks:load", function() {
  var old_med_search =  function() {

    $('#collapseMedicationSearch').on('shown.bs.collapse', function(){
      $('#medication').trigger('focus');
    })

    if ($('.search-medications').length > 0) {
      $('.search-medications').on('focus', function() {
        $('#meds-search-wrapper').fadeIn();

        if ($('.search-medications').val().length > 0) {
          if ($('.search-medications').val().length > 1) { $('.spinner').show(); }
          meds_query_search();
        }
      });
      $('.search-medications').on('keyup', function() {
        if ($('.search-medications').val().length > 0) {
          if ($('.search-medications').val().length > 1) { $('.spinner').show(); }
          meds_query_search();
        } else {
          $('#meds-search-container').hide();
        }
      });
    }
    if ($('#meds-search-wrapper').length > 0) {
      $('#meds-search-wrapper').on('click', function() {
        $('.spinner').hide();
        $('.search-medications').animate({width:140}, 300);
        $('#meds-search-container').hide();
        $('#meds-search-wrapper').fadeOut();
      });
      return;
    }
  };

  var meds_query_search = function() {

    $.get('/med-search.json',
      {query: $('.search-medications').val()},
      function(data) {
        if ($('#meds-search-wrapper').is(":visible")) {
          if (data.medications.length > 0) {
            $('#meds-search-container').show();
            $('.meds-search-results-list').empty();

            for (let med of Array.from(data.medications)) {
              (function(med) {
                $('.meds-search-results-list').append('<li style="list-style-type: none;"><a href="" class="medicos" data-meds-code="'+med.id+'">'+med.drug_name + ' -- ' + med.form + '</a></li>');
              })(med);
            }
            $('.meds-search-results-list li:nth-child(even)').addClass('search-results-list-alt');
            $('a.medicos').click(function(e) {
              e.preventDefault();
              $('[data-handle="medication"]').val($(e.target).data('meds-code'));
              $('#medication-display').text($(e.target).text());
              $('#frequency-choice').removeClass('d-none');
              $('.spinner').hide();
              $('.search-medications').val('');
              $('#meds-search-container').fadeOut();
              return $('#meds-search-wrapper').fadeOut();
            });
          } else {
            $('#meds-search-container').hide();
          }
          $('.spinner').hide();
          return;
        }
    });
  };

  var add_meds_loader = function() {
    remove_meds_loader();
    if ($('#meds-search-wrapper').is(":visible")) {
      return medsLoader = new ajaxLoader($('#meds-search-wrapper'), { bgColor : '#fff', duration : 0.1, opacity : 0.7, classOveride : false });
    }
  };

  var remove_meds_loader = function() {
    if (medsLoader) { return medsLoader.remove(); }
  };

  $(document).ajaxStop(() => remove_meds_loader());

