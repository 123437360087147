document.addEventListener("turbolinks:load", function() {
  $( "#review-control" ).on( "click", ".resolution", function( e ) {

    $("#resolution-control").addClass("col");
    $("#resolution-control").removeClass("col-5");
    $("#resolution-buttons").addClass("col-5");

    $("#resolution-form").collapse('show');

    $("#new-status").val(this.dataset.status);

  });

  $( "#cancel-resolution" ).on( "click", function( e ) {
    $("#exemption_resolution_note").val("");
    $("#resolution-control").addClass("col-5");
    $("#resolution-control").removeClass("col");

    $("#resolution-buttons").removeClass("col-5");
    $("#resolution-form").collapse('hide');
  });

  $( "#urgent-review").on("click", function (e) {
    if (this.checked) {
      $("#urgent-review-docs").show();
    } else {
      $("#urgent-review-docs").hide();
    }
  });
});
