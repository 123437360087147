  let diagLoader = null;

  document.addEventListener("turbolinks:load", function() {

    $('#collapseDiagnosisSearch').on('shown.bs.collapse', function(){
      $('#diagnosis').trigger('focus');
    })

    if ($('.search-diagnoses').length > 0) {
      $('.search-diagnoses').on('focus', function() {
        $('#diag-search-wrapper').fadeIn();

        if ($('.search-diagnoses').val().length > 0) {
          if ($('.search-diagnoses').val().length > 1) { $('.spinner').show(); }
          diag_query_search();
        }
      });
      $('.search-diagnoses').on('keyup', function() {
        if ($('.search-diagnoses').val().length > 0) {
          if ($('.search-diagnoses').val().length > 1) { $('.spinner').show(); }
          diag_query_search();
        } else {
          $('#diag-search-container').hide();
        }
      });
    }
    if ($('#diag-search-wrapper').length > 0) {
      $('#diag-search-wrapper').on('click', function() {
        $('.spinner').hide();
        $('.search-diagnoses').animate({width:140}, 300);
        $('#diag-search-container').hide();
        $('#diag-search-wrapper').fadeOut();
      });
      return;
    }
  });

  var diag_query_search = function() {
    $.get('/diagnosis-search.json',
      {query: $('.search-diagnoses').val()},
      function(data) {
        if ($('#diag-search-wrapper').is(":visible")) {
          if (data.diagnoses.length > 0) {
            $('#diag-search-container').show();
            $('.diag-search-results-list').empty();

            for (let diagnosis of Array.from(data.diagnoses)) {
              (function(diagnosis) {
                $('.diag-search-results-list').append('<li style="list-style-type: none;"><a href="" class="diagnos" data-diagnosis-code="'+diagnosis.id+'">'+diagnosis.code_2 + ' ' + diagnosis.description+'</a></li>');
              })(diagnosis);
            }
            $('.diag-search-results-list li:nth-child(even)').addClass('search-results-list-alt');
            $('a.diagnos').click(function(e) {
              e.preventDefault();
              $('[data-handle="diagnosis"]').val($(e.target).data('diagnosis-code'));
              $('#diagnosis-display').text($(e.target).text());
              $('.spinner').hide();
              $('.search-diagnoses').val('');
              $('#diag-search-container').fadeOut();
              return $('#diag-search-wrapper').fadeOut();
            });
          } else {
            $('#diag-search-container').hide();
          }
          $('.spinner').hide();
          return;
        }
    });
  };

  var add_diag_loader = function() {
    remove_diag_loader();
    if ($('#diag-search-container').is(":visible")) {
      return diagLoader = new ajaxLoader($('#diag-search-container'), { bgColor : '#fff', duration : 0.1, opacity : 0.7, classOveride : false });
    }
  };

  var remove_diag_loader = function() {
    if (diagLoader) { return diagLoader.remove(); }
  };

  $(document).ajaxStop(() => remove_diag_loader());

