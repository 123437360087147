// app/javascript/controllers/clickable_row_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row"];

  connect() {
    console.log("Clickable row controller connected");  
    this.rowTargets.forEach(row => {
      row.addEventListener("click", () => {
        console.log("Row clicked");
        const url = row.dataset.href;
        if (url) {
          window.location.href = url;
        }
      });
    });
  }
}
