import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["recipId", "subject", "msgBody", "sendButton", "form", "msgModal", "msgModalDialog"];

  connect() {

  }

  postMessage(event) {
    this.formTarget.addEventListener('ajax:success', this.handleSuccess.bind(this))
    this.formTarget.addEventListener('ajax:error', this.handleError.bind(this))
  }

  handleSuccess(event) {
    // TODO add some notification in the UI for the succes
    // TODO update the status in the UI to confirmed
    // window.location.reload(true);
    $("#msg-modal-link").addClass("d-none")
    this.close()
  }

  handleError(event) {
    // manage the error state
  }

  close() {
    document.body.classList.remove("modal-open");
    this.msgModalTarget.removeAttribute("style");
    this.msgModalTarget.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
}

}
