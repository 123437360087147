document.addEventListener("turbolinks:load", function() {
  const exp_form = document.getElementById("expiration_form");
  if (exp_form) {
    document.getElementById("expiration_form").addEventListener("submit", function(e) {
      e.preventDefault(); 
      const formData = new FormData(this);

      fetch(this.action, {
        method: this.method,
        body: formData,
        headers: {},
        credentials: 'same-origin' 
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Access Content-Disposition header to get filename
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = "download.csv";
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, ''); 
          }
        }
        return response.blob().then(blob => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => console.error('Error:', error));
    });
  }
});
