import { Controller } from 'stimulus';
import SignaturePad from 'signature_pad';

export default class extends Controller {
  static targets = ['form', 'acceptCheck', 'playerWrapper', 'playerSig', 'witnessSig', 'subjectSignatureData', 'witnessWrapper', 'witnessSignatureData', 'submitButton'];

  playerCanvas = null;
  playerSignaturePad = null;

  witnessCanvas = null;
  witnessSignaturePad = null;

  connect() {
    // console.log("release signature form connected");
    this.setupSignaturePad();

    this.element.addEventListener('blur', this.onBlur, true);
    this.element.addEventListener('keyup', this.onBlur, true);
    this.element.addEventListener('submit', this.onSubmit);
    
    this.acceptCheckTarget.addEventListener('change', this.checkAccepted.bind(this));
    this.element.addEventListener('submit', this.onSubmit.bind(this));

    this.playerCanvas.addEventListener('input', this.checkPlayerWrapper.bind(this));
    this.playerCanvas.addEventListener('change', this.checkPlayerWrapper.bind(this)); // Depending on the specific need

    this.playerSigTarget.addEventListener('mousein', this.startPlayerDrawing.bind(this));
    this.playerSigTarget.addEventListener('mousemove', this.startPlayerDrawing.bind(this));
    this.playerSigTarget.addEventListener('mouseup', this.stopPlayerDrawing.bind(this));
    this.playerSigTarget.addEventListener('mouseout', this.stopPlayerDrawing.bind(this));

    this.witnessSigTarget.addEventListener('mousein',   this.startWitnessDrawing.bind(this));
    this.witnessSigTarget.addEventListener('mousemove', this.startWitnessDrawing.bind(this));
    this.witnessSigTarget.addEventListener('mouseup',   this.stopWitnessDrawing.bind(this));
    this.witnessSigTarget.addEventListener('mouseout',  this.stopWitnessDrawing.bind(this));

        // For touch screens
        // this.canvasTarget.addEventListener('touchstart', this.startDrawing.bind(this));
        // this.canvasTarget.addEventListener('touchmove', this.draw.bind(this));
        // this.canvasTarget.addEventListener('touchend', this.stopDrawing.bind(this));


    this.resizeCanvas();
    // this.checkFormValidity();  // Initial check on connect
  }

  startPlayerDrawing(event) {
    // console.log("startPlayerDrawing");
    this.isPlayerDrawing = true;
  }

  playerDrawing(event) {
    // console.log("playerDrawing");
    if (!this.isPlayerDrawing) return;
    this.isPlayerDrawing = false;
  }

  stopPlayerDrawing(event) {
    // console.log("stopPlayerDrawing");
    if (!this.isPlayerDrawing) return;
    this.isPlayerDrawing = false;
    this.collectPlayerSignatureData();
    this.checkFormValidity();
  }

  startWitnessDrawing(event) {
    // console.log("startWitnessDrawing");
    this.isWitnessDrawing = true;
  }

  witnessDrawing(event) {
    // console.log("witnessDrawing");
    if (!this.isWitnessDrawing) return;
    this.isWitnessDrawing = false;
  }

  stopWitnessDrawing(event) {
    // console.log("stopWitnessDrawing");
    if (!this.isWitnessDrawing) return;
    this.isWitnessDrawing = false;
    this.collectWitnessSignatureData();
    this.checkFormValidity();
  }

  setupSignaturePad() {
    this.playerCanvas = this.playerWrapperTarget.querySelector("canvas");
    this.playerSignaturePad = new SignaturePad(this.playerCanvas, {});
    this.witnessCanvas = this.witnessWrapperTarget.querySelector("canvas");
    this.witnessSignaturePad = new SignaturePad(this.witnessCanvas, {});
    this.playerSignaturePad.clear();
    this.witnessSignaturePad.clear();
  }

  onBlur = (event) => {
    // console.log("onBlur: ", event.target);
    this.checkFormValidity();  
  }

  onSubmit(event) {  
    this.collectSignatureData();
    // Perform manual validation for signatures
    const playerSignature = this.subjectSignatureDataTarget.value;
    const witnessSignature = this.witnessSignatureDataTarget.value;
    const acceptCheck = this.element.querySelector('#info_release_terms_accepted').checked;
    if (!playerSignature || !witnessSignature || !acceptCheck) {
      event.preventDefault(); // Stop form submission
      alert('Please provide both signatures and accept the terms.');
    }
  }

  checkPlayerWrapper(event) {
    // console.log('User left the field or interacted with the canvas.');

    if (event.target === this.inputFieldTarget) {
      console.log('Input field was left.');
    } else if (event.target === this.canvasTarget) {
      console.log('Canvas was written on.');
    }
  }

  collectPlayerSignatureData() {
    // console.log("collectPlayerSignatureData");
    if (this.playerSignaturePad.isEmpty()) {
      // alert("Please provide a player signature before submitting.");
    } else {
      let subjectDataURL = this.playerSignaturePad.toDataURL();
      this.subjectSignatureDataTarget.value = subjectDataURL;
    }

  }

  collectWitnessSignatureData() {
    // console.log("collecting witness signature data");
    if (this.witnessSignaturePad.isEmpty()) {
      // alert("Please provide a witness signature before submitting.");
    } else {
      let witnessDataURL = this.witnessSignaturePad.toDataURL();
      this.witnessSignatureDataTarget.value = witnessDataURL;
    }
  }

  collectSignatureData() {
    // console.log("collecting signature data");
    if (this.playerSignaturePad.isEmpty()) {
      //alert("Please provide a player signature before submitting.");
    } else {
      let subjectDataURL = this.playerSignaturePad.toDataURL();
      this.subjectSignatureDataTarget.value = subjectDataURL;
    }

    if (this.witnessSignaturePad.isEmpty()) {
      //alert("Please provide a witness signature before submitting.");
    } else {
      let witnessDataURL = this.witnessSignaturePad.toDataURL();
      this.witnessSignatureDataTarget.value = witnessDataURL;
    }
  }

  validateForm() {
    let isValid = true;

    if (!this.acceptCheckTarget.checked) {
      alert("You must agree to the terms and conditions before submitting.");
      isValid = false;
    }

    if (this.playerSignaturePad.isEmpty()) {
      isValid = false;
    }

    if (this.witnessSignaturePad.isEmpty()) {
      isValid = false;
    }

    return isValid;
  }

  checkAccepted () {
    // console.log("checking accepted")
    // console.log("acceptCheckTarget.checked: ", this.acceptCheckTarget.checked)
    this.checkFormValidity();
  }

  validateField(field) {
    if (!this.shouldValidateField(field))
      return true;
    const isValid = field.checkValidity();
    field.classList.toggle("is-invalid", !isValid);
    this.refreshErrorForInvalidField(field, isValid);
    return isValid;
  }


  checkFormValidity() {
    // console.log("checking form validity");
    const isValid = this.element.checkValidity() && !this.playerSignaturePad.isEmpty() && !this.witnessSignaturePad.isEmpty() && this.acceptCheckTarget.checked;
    this.submitButtonTarget.disabled = !isValid;
  }

  get firstInvalidField() {
    return this.formFields.find(field => !field.checkValidity());
  }

  shouldValidateField(field) {
    return !field.disabled && !['reset', 'submit', 'button'].includes(field.type);
  }

  refreshErrorForInvalidField(field, isValid) {
    this.removeExistingErrorMessage(field);
    if (!isValid)
      this.showErrorForInvalidField(field);
  }

  removeExistingErrorMessage(field) {
    const fieldContainer = field.closest('.field');
    if (!fieldContainer) return;

    const existingErrorMessageElement = fieldContainer.querySelector('.error');
    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement);
  }

  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio =  Math.max(window.devicePixelRatio || 1, 1)

    // This part causes the canvas to be cleared
    this.playerCanvas.width = this.playerCanvas.offsetWidth * ratio
    this.playerCanvas.height = this.playerCanvas.offsetHeight * ratio
    this.playerCanvas.getContext("2d").scale(ratio, ratio)

    // This library does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, you
    // have to clear it manually.
    this.playerSignaturePad.clear()

    this.witnessCanvas.width = this.witnessCanvas.offsetWidth * ratio
    this.witnessCanvas.height = this.witnessCanvas.offsetHeight * ratio
    this.witnessCanvas.getContext("2d").scale(ratio, ratio)
    this.witnessSignaturePad.clear()
  }

  disconnect() {
    this.element.removeEventListener('blur', this.onBlur);
    this.element.removeEventListener('keyup', this.onBlur);
    this.element.removeEventListener('submit', this.onSubmit);
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit);
  }

  clearPlayerSig () {
    this.playerSignaturePad.clear()
  }

  clearWitnessSig () {
    this.witnessSignaturePad.clear()
  }
}
