import { Controller } from 'stimulus';

export default class extends Controller {
  //nicked this from https://www.jorgemanrubia.com/2019/02/16/form-validations-with-html5-and-modern-rails/
  //https://github.com/jorgemanrubia/rails-form-validations-example/blob/master/app/javascript/controllers/form_controller.js
  static targets = ['form', 'fileUploadBtn', 'formSubmit'];

  connect() {
    this.initFileInput()

    this.element.setAttribute('novalidate', true)
    this.element.addEventListener('focus', this.onFocus, true)
    this.element.addEventListener('blur', this.onBlur, true)
    this.element.addEventListener('keyup', this.onBlur, true)
    this.element.addEventListener('submit', this.onSubmit)
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)

    this.formSubmitTarget.addEventListener('click', this.onSubmit)
  }

  disconnect () {
    this.element.removeEventListener('blur', this.onBlur)
    this.element.removeEventListener('keyup', this.onBlur)
    this.element.removeEventListener('submit', this.onSubmit)
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
  }

  initFileInput () {
    bsCustomFileInput.init();

    $('#customFile').on('change',function(){
      var fileName = $(this).val();
      //https://github.com/Johann-S/bs-custom-file-input/pull/38/files
      //Dont know why this is still happening. Changelog says it should be done in code now.
      //https://github.com/Johann-S/bs-custom-file-input/blob/db0e8a2b068504154ff0b4acc978ff7ed617625a/CHANGELOG.md#130-2018-11-16

      var splitted = fileName.split('\\');
      fileName = splitted[splitted.length -1];
      //replace the "Choose a file" label
      $(this).next('.custom-file-label').html(fileName);
    })
  }

  onFocus = (event) =>{ }

  onBlur = (event) => {
    this.validateField(event.target)
  }

  onSubmit = (event) => {
    if (!this.validateForm()) {
      event.preventDefault()
      this.firstInvalidField.focus()
    }
  }

  validateAndSubmit () {
    this.submit()
  }

  validateForm () {
    let isValid = true
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField (field) {
    if (!this.shouldValidateField(field))
      return true
    const isValid = !!field.value
    field.classList.toggle("is-invalid", !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField (field) {
    if (field.name === undefined ){
      return false
    }
    return field.dataset.validate == "true"
  }

  refreshErrorForInvalidField (field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid)
      this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.field')
    if(!fieldContainer)
      return;

    const existingErrorMessageElement = fieldContainer.querySelector('.error')

    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField (field) {
    field.classList.add('error');
    if (field.classList.contains('flatpickr-input')) {
      field.nextSibling.classList.add('error');
    }
    //skipping filling up the form with text until it fails server side validation
    field.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
  }

  buildFieldErrorHtml (field) {
    return `<span class="error">${field.validationMessage}</span>`
  }

  get formFields () {
    return Array.from(this.element.elements)
  }

  get firstInvalidField () {
    return this.formFields.find(field => field.classList.contains('is-invalid'))
  }

}
