import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['form', 'teamPrivacyCheck'];

  updateTeamPrivacy(event) {
    this.formTarget.submit();
  }

}
