import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['form'];

  connect(){}

  assign(event) {
    this.formTarget.submit();
  }

  // onPostSuccess(event) {
  //   console.log("success!");
  // }

}
